import { MultipleCheckedItemsToolbar } from "components/common/multipleCheckedItemsToolbar/MultipleCheckedItemsToolbar";
import { useQuery, useSelector } from "hooks";
import { HighlightedRow } from "api/other/models";
import { useState } from "react";
import { InvoiceType, TradingDocumentType } from "api/trading-documents/models";
import { ClickOutsideHandler } from "components/utils";
import { Select } from "components/miloDesignSystem/molecules/select";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiCheck } from "components/miloDesignSystem/atoms/icons/MdiCheck";
import { MdiRule } from "components/miloDesignSystem/atoms/icons/MdiRule";
import { MdiEdit } from "components/miloDesignSystem/atoms/icons/MdiEdit";
import { MdiDownloadXml } from "components/miloDesignSystem/atoms/icons/MdiDownloadXml";
import { cx, dateUtils } from "utilities";
import { MdiReceiptLong } from "components/miloDesignSystem/atoms/icons/MdiReceiptLong";
import { MdiDownloadPdf } from "components/miloDesignSystem/atoms/icons/MdiDownloadPdf";
import { UUID } from "api/types";
import { ReplyModal } from "./ReplyModal";
import { assertIsDefined } from "utilities/assertIsDefined";
import { Popover } from "components/miloDesignSystem/atoms/popover";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { Button as DesignSystemButton } from "components/miloDesignSystem/atoms/button";
import { languages } from "CONSTANTS";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { tradingDocumentsActions } from "api/trading-documents/actions";
import { MdiZip } from "components/miloDesignSystem/atoms/icons/MdiZip";
import styles from "./ActionToolbar.module.css";
import { DatePicker } from "components/utils/datePicker";
import { MdiEvent } from "components/miloDesignSystem/atoms/icons/MdiEvent";
import { MdiSensorOccupied } from "components/miloDesignSystem/atoms/icons/MdiSensorOccupied";
import { MdiDelete } from "components/miloDesignSystem/atoms/icons/MdiDelete";
import { MdiMail } from "components/miloDesignSystem/atoms/icons/MdiMail";
import { MdiDownloadDoc } from "components/miloDesignSystem/atoms/icons/MdiDownloadDoc";
import { FileDownloadHandler } from "components/miloDesignSystem/atoms/fileDownloadHandler";
import { tradingDocumentFileFactory } from "api/trading-documents/calls";

interface Props {
  close: () => void;
  quantity: number;
  highlightedRows?: HighlightedRow[];
  tradingDocument?: { type: TradingDocumentType; invoiceType: InvoiceType };
}

export const ActionToolbar = ({ close, quantity, highlightedRows, tradingDocument }: Props) => {
  const { query } = useQuery();
  const panelId = query.panelId;
  const {
    bulkSalesInvoiceConfirmMutation,
    replyModal,
  } = tradingDocumentsActions.useBulkSalesAccountConfirmation(panelId);
  const ledgerAccounts = useSelector(store => store.partials.ledgerAccounts);
  const [chosenAccountancyAccount, setChosenAccountancyAccount] = useState<string>();
  const [language, setLanguage] = useState("");
  const [invoiceIssueDate, setInvoiceIssueDate] = useState(dateUtils.formatDateToIso(new Date()));
  const [invoiceDeliveryDate, setInvoiceDeliveryDate] = useState(
    dateUtils.formatDateToIso(new Date()),
  );
  const handleDownloadOptimaTradeModuleZip = tradingDocumentsActions.useDownloadOptimaTradeModuleZip();
  const issueAndDeliveryDatesMutation = tradingDocumentsActions.useBulkPatchTradingDocuments();
  const ledgerAccountMutation = tradingDocumentsActions.useBulkPatchTradingDocuments();
  const fiscalizeReceiptsMutation = tradingDocumentsActions.useFiscalizeReceipts(panelId);
  const handleSalesInvoicesBulkPdfDownload = tradingDocumentsActions.useSalesInvoicesBulkPdfDownload();
  const handleCorrectionInvoicesBulkPdfDownload = tradingDocumentsActions.useCorrectionInvoicesBulkPdfDownload();
  const handleInvoicesBulkXmlDownload = tradingDocumentsActions.useInvoicesBulkXmlDownload();
  const handleCorrectionsBulkXmlDownload = tradingDocumentsActions.useCorrectionsBulkXmlDownload();
  const sendBulkEmailNotification = tradingDocumentsActions.useSendMultipleEmailNotifications();

  assertIsDefined(highlightedRows);

  return (
    <MultipleCheckedItemsToolbar
      actionButtons={
        <>
          {tradingDocument?.invoiceType === "SALES" && (
            <Tooltip title="Zatwierdź faktury">
              <IconButton
                icon={MdiCheck}
                onClick={() => {
                  bulkSalesInvoiceConfirmMutation.mutate({
                    tradingDocumentsIds: highlightedRows.map(row => String(row.id)),
                  });
                }}
                variant="transparent"
                theme="dark"
              />
            </Tooltip>
          )}
          {tradingDocument?.invoiceType === "SALES" && (
            <Tooltip title="Wyślij e-mail z przypomnieniem">
              <IconButton
                icon={MdiMail}
                isLoading={sendBulkEmailNotification.isLoading}
                onClick={() => {
                  sendBulkEmailNotification.mutate({
                    tradingDocumentsIds: highlightedRows.map(row => String(row.id)),
                  });
                }}
                variant="transparent"
                theme="dark"
              />
            </Tooltip>
          )}
          {(tradingDocument?.invoiceType === "SALES" ||
            tradingDocument?.invoiceType === "ADVANCE" ||
            tradingDocument?.invoiceType === "PROFORMA") && (
            <Popover
              hideOnClickOutside={false}
              content={({ hide }) => (
                <ClickOutsideHandler
                  onClickOutside={() => {
                    hide();
                    setInvoiceIssueDate(dateUtils.formatDateToIso(new Date()));
                    setInvoiceDeliveryDate(dateUtils.formatDateToIso(new Date()));
                  }}
                  outsideClickIgnoreClass={ignoreClickOutsideClassName}
                >
                  <div>
                    <Typography className="mt-2 mb-1" fontSize="14" fontWeight="700">
                      Wskaż nową datę wystawienia
                    </Typography>
                    <div className="d-flex">
                      <div className={cx(styles.datePicker, "position-relative mr-1")}>
                        <DatePicker
                          calendarClassName={ignoreClickOutsideClassName}
                          overwrites={{
                            container: { className: ignoreClickOutsideClassName },
                            popup: {
                              className: cx(ignoreClickOutsideClassName, styles.datePicker),
                            },
                          }}
                          placeholder="Wybierz"
                          className={styles.date}
                          value={invoiceIssueDate}
                          selectsStart
                          removeDate={false}
                          tabIndex={-1}
                          onChange={date => {
                            if (!date) return;
                            setInvoiceIssueDate(dateUtils.formatDateToIso(date));
                          }}
                        />
                      </div>
                    </div>
                    <Typography className="mt-2 mb-1" fontSize="14" fontWeight="700">
                      Wskaż nową datę sprzedaży
                    </Typography>
                    <div className="d-flex">
                      <div className={cx(styles.datePicker, "position-relative mr-1")}>
                        <DatePicker
                          calendarClassName={ignoreClickOutsideClassName}
                          overwrites={{
                            container: { className: ignoreClickOutsideClassName },
                            popup: {
                              className: cx(ignoreClickOutsideClassName, styles.datePicker),
                            },
                          }}
                          placeholder="Wybierz"
                          className={styles.date}
                          value={invoiceDeliveryDate}
                          selectsStart
                          removeDate={false}
                          tabIndex={-1}
                          onChange={date => {
                            if (!date) return;
                            setInvoiceDeliveryDate(dateUtils.formatDateToIso(date));
                          }}
                        />
                      </div>
                    </div>
                    <div className="d-flex flex-1 justify-content-end mt-3">
                      <DesignSystemButton
                        className={cx("text-uppercase", styles.submitBtn)}
                        size="small"
                        variant="deepPurple"
                        onClick={() => {
                          issueAndDeliveryDatesMutation.mutate(
                            {
                              ids: highlightedRows.map(row => String(row.id)),
                              toUpdate: {
                                invoiceIssueDate,
                                invoiceDeliveryDate,
                              },
                            },
                            {
                              onSuccess: hide,
                            },
                          );
                        }}
                        isLoading={issueAndDeliveryDatesMutation.isLoading}
                      >
                        Zmień daty
                      </DesignSystemButton>
                    </div>
                  </div>
                </ClickOutsideHandler>
              )}
              variant="DARK"
            >
              <div>
                <Tooltip title="Zmień datę wystawienia lub sprzedaży">
                  <IconButton icon={MdiEvent} variant="transparent" theme="dark" />
                </Tooltip>
              </div>
            </Popover>
          )}
          <Tooltip title="Zażądaj zmian">
            <IconButton icon={MdiRule} variant="transparent" theme="dark" />
          </Tooltip>
          <Tooltip title="Przypisz">
            <IconButton icon={MdiSensorOccupied} variant="transparent" theme="dark" />
          </Tooltip>
          <Tooltip title="Usuń">
            <IconButton icon={MdiDelete} variant="transparent" theme="dark" />
          </Tooltip>
          <Popover
            hideOnClickOutside={false}
            content={({ hide }) => (
              <ClickOutsideHandler
                onClickOutside={() => {
                  hide();
                  setChosenAccountancyAccount(undefined);
                }}
                outsideClickIgnoreClass={ignoreClickOutsideClassName}
              >
                <div>
                  <Typography className="mt-2 mb-3" fontSize="14" fontWeight="700">
                    Jakie konto księgowe przypisać?
                  </Typography>
                  <div className="d-flex">
                    <div>
                      <Select
                        items={ledgerAccounts.map(account => ({
                          value: account.id,
                          text: account.name,
                          type: MenuItemType.TEXT,
                        }))}
                        onChange={account =>
                          account && setChosenAccountancyAccount(String(account))
                        }
                        label="Wybierz konto"
                        selected={chosenAccountancyAccount ?? null}
                      />
                    </div>
                  </div>
                  <div className="d-flex flex-1 justify-content-end mt-3">
                    <DesignSystemButton
                      className={cx("text-uppercase", styles.submitBtn)}
                      disabled={
                        chosenAccountancyAccount === undefined || ledgerAccountMutation.isLoading
                      }
                      size="small"
                      variant="deepPurple"
                      onClick={() => {
                        issueAndDeliveryDatesMutation.mutate(
                          {
                            ids: highlightedRows.map(row => String(row.id)),
                            toUpdate: {
                              ledgerAccount: chosenAccountancyAccount,
                            },
                          },
                          {
                            onSuccess: hide,
                          },
                        );
                      }}
                      isLoading={issueAndDeliveryDatesMutation.isLoading}
                    >
                      Zmień konto księgowe
                    </DesignSystemButton>
                  </div>
                </div>
              </ClickOutsideHandler>
            )}
            variant="DARK"
          >
            <div>
              <Tooltip title="Zmień konta księgowe dla zaznaczonych">
                <IconButton icon={MdiEdit} variant="transparent" theme="dark" />
              </Tooltip>
            </div>
          </Popover>
          <FileDownloadHandler
            type="xml"
            factoryFn={() =>
              tradingDocumentFileFactory.fakirXml(highlightedRows.map(row => String(row.id)))
            }
          >
            {({ download, isLoading }) => (
              <Tooltip title="Pobierz Wapro fakir dla zaznaczonych">
                <IconButton
                  isLoading={isLoading}
                  icon={MdiDownloadXml}
                  onClick={download}
                  variant="transparent"
                  theme="dark"
                />
              </Tooltip>
            )}
          </FileDownloadHandler>
          {tradingDocument &&
            (tradingDocument.invoiceType === "SALES" ||
              tradingDocument.invoiceType === "PROFORMA" ||
              tradingDocument.invoiceType === "ADVANCE" ||
              tradingDocument.invoiceType === "CORRECTION") && (
              <Popover
                hideOnClickOutside={false}
                content={({ hide }) => (
                  <ClickOutsideHandler
                    onClickOutside={() => {
                      hide();
                      setLanguage("");
                    }}
                    outsideClickIgnoreClass={ignoreClickOutsideClassName}
                  >
                    <div>
                      <Typography className="mt-2 mb-3" fontSize="14" fontWeight="700">
                        Wybierz język faktury
                      </Typography>
                      <div className="d-flex">
                        <div>
                          <Select
                            items={languages.map(language => ({
                              value: language.value,
                              text: language.language,
                              type: MenuItemType.TEXT,
                            }))}
                            onChange={countryCode =>
                              countryCode && setLanguage(String(countryCode))
                            }
                            label="Wybierz język"
                            selected={language}
                          />
                        </div>
                      </div>
                      <div className="d-flex flex-1 justify-content-end mt-3">
                        <DesignSystemButton
                          className="text-uppercase w-100"
                          size="small"
                          variant="deepPurple"
                          onClick={() => {
                            if (tradingDocument.invoiceType === "CORRECTION") {
                              handleCorrectionInvoicesBulkPdfDownload(
                                highlightedRows ? highlightedRows.map(row => String(row.id)) : [],
                                language,
                              );
                            } else {
                              handleSalesInvoicesBulkPdfDownload(
                                highlightedRows ? highlightedRows.map(row => String(row.id)) : [],
                                language,
                              );
                            }
                            hide();
                          }}
                        >
                          Pobierz PDFy wybranych faktur
                        </DesignSystemButton>
                      </div>
                    </div>
                  </ClickOutsideHandler>
                )}
                variant="DARK"
              >
                <div>
                  <Tooltip title="Pobierz PDF dla zaznaczonych">
                    <IconButton icon={MdiDownloadPdf} variant="transparent" theme="dark" />
                  </Tooltip>
                </div>
              </Popover>
            )}
          <FileDownloadHandler
            type="epp"
            factoryFn={() =>
              tradingDocumentFileFactory.revisorPdf(highlightedRows.map(row => String(row.id)))
            }
          >
            {({ download, isLoading }) => (
              <Tooltip title="Pobierz Revisor dla zaznaczonych">
                <IconButton
                  isLoading={isLoading}
                  icon={MdiDownloadDoc}
                  onClick={download}
                  variant="transparent"
                  theme="dark"
                />
              </Tooltip>
            )}
          </FileDownloadHandler>

          {tradingDocument && tradingDocument.invoiceType !== "CORRECTION" && (
            <Tooltip title="Pobierz XML dla zaznaczonych">
              <IconButton
                icon={MdiDownloadXml}
                onClick={() =>
                  handleInvoicesBulkXmlDownload(tradingDocument, parseRows(highlightedRows).string)
                }
                variant="transparent"
                theme="dark"
              />
            </Tooltip>
          )}

          {tradingDocument && tradingDocument.invoiceType === "SALES" && (
            <Tooltip title="Wyślij paragony do drukarki dla zaznaczonych">
              <IconButton
                icon={MdiReceiptLong}
                onClick={() =>
                  fiscalizeReceiptsMutation.mutate({
                    tradingDocuments: highlightedRows.map(row => String(row.id)),
                  })
                }
                variant="transparent"
                theme="dark"
              />
            </Tooltip>
          )}

          {tradingDocument && tradingDocument.invoiceType === "SALES" && (
            <Tooltip title="Pobierz ZIP moduł handel [Optima] dla zaznaczonych">
              <IconButton
                icon={MdiZip}
                onClick={() => handleDownloadOptimaTradeModuleZip(parseRows(highlightedRows).array)}
                variant="transparent"
                theme="dark"
              />
            </Tooltip>
          )}

          {tradingDocument && tradingDocument.invoiceType === "CORRECTION" && (
            <Tooltip title="Pobierz XML dla zaznaczonych">
              <IconButton
                icon={MdiDownloadXml}
                onClick={() =>
                  handleCorrectionsBulkXmlDownload(
                    tradingDocument,
                    parseRows(highlightedRows).string,
                  )
                }
                variant="transparent"
                theme="dark"
              />
            </Tooltip>
          )}

          {replyModal.isOpen && replyModal.state && (
            <ReplyModal bulkInvoiceConfirmation={replyModal.state} close={replyModal.close} />
          )}
        </>
      }
      close={close}
      quantity={quantity}
    />
  );
};

const parseRows = (rows: HighlightedRow[]) => {
  let stringArray = rows.map(tradingDocument => tradingDocument.id);
  return {
    string: stringArray.join(","),
    array: stringArray as UUID[],
  };
};

const ignoreClickOutsideClassName = "trading-documents-list-popover-ignore-class-name";
